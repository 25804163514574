import { ENDPOINT_VERSIONS } from './_endpoint-versions';

export const PURCHASES_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2_2}/purchases`;

export const PURCHASES_GET_BY_ID_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2_1}/purchases/${purchaseId}`;

export const PURCHASES_GET_STATISTICS_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/statistics`;

export const PURCHASE_GET_PRICES_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/prices`;

export const PURCHASE_GET_TICKETS_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/tickets`;

export const PURCHASE_UPDATE_PRICES_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/update-price`;

export const PURCHASE_DELETE_TICKETS_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/delete-tickets`;

export const PURCHASE_ADD_TICKETS_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/add-tickets`;

export const PURCHASES_UPDATE_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/update-details`;

export const PURCHASES_DELETE_SPLIT_ENDPOINT = (purchaseId: string, splitId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/splits/${splitId}/delete`;

export const PURCHASES_CANCEL_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/cancel`;

export const PURCHASES_COMPLETE_ENDPOINT = (purchaseId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/${purchaseId}/complete`;
export const PURCHASES_MULTIPLE_COMPLETE_ENDPOINT = () =>
    `/${ENDPOINT_VERSIONS.V2}/purchases/complete`;

export const PURCHASES_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2_1}/purchases/create`;

export const PURCHASES_GET_EVENTS = `/${ENDPOINT_VERSIONS.V2}/purchases/purchase-event-filter-query`;
