import { zodResolver } from '@hookform/resolvers/zod';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { Alert, CircularProgress, FormHelperText, Grid, Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import BlocksSelectDataWrapper from 'src/app/components/data-wrappers/inputs/BlocksSelectDataWrapper';
import RowsSelectDataWrapper from 'src/app/components/data-wrappers/inputs/RowsSelectDataWrapper';
import SeatsSelectDataWrapper from 'src/app/components/data-wrappers/inputs/SeatsSelectDataWrapper';
import renderPriceInput from 'src/app/components/forms/helpers/renderPriceInput';
import UserPermissions from 'src/app/utilities/helpers/userPermissions';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import { zodOptionalDdropdownSchema } from 'src/app/utilities/zod/zodOptionalDdropdownSchema';
import { zodRequiredNumberInputSchema } from 'src/app/utilities/zod/zodRequiredNumberInputSchema';
import { zodRequiredStringSchema } from 'src/app/utilities/zod/zodRequiredStringSchema';
import { PURCHASE_STATUS } from 'src/data/enums/purchase';
import Price from 'src/data/models/common/price';
import TicketFile from 'src/data/models/common/ticketFile';
import { defaultCurrency } from 'src/shared/currencies';
import { formatPrice } from 'src/shared/helpers/formatPrice';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import Chip from 'src/view/components/chip/Chip';
import DictionaryGrid from 'src/view/components/dictionary-grid/DictionaryGrid';
import FormButtons from 'src/view/components/form/FormButtons';
import CheckIcon from 'src/view/icons/CheckIcon';
import z from 'zod';

const useStyles = makeStyles((theme: Theme) => ({
    editText: {
        marginTop: theme.spacing(1),
    },
    spacingLeft: {
        marginLeft: theme.spacing(1),
    },
}));

export const ticketDetailsValidationSchema = z.object({
    purchasePrice: z
        .object({
            value: zodRequiredNumberInputSchema,
            currency: zodRequiredStringSchema,
        })
        .nullable(),
    block: zodOptionalDdropdownSchema.nullable(),
    row: zodOptionalDdropdownSchema.nullable(),
    seat: zodOptionalDdropdownSchema.nullable(),
});

export const contractTicketDetailsValidationSchema = z.object({
    purchasePrice: z
        .object({
            value: zodRequiredNumberInputSchema.nullable(),
            currency: zodRequiredStringSchema.nullable(),
        })
        .nullable(),
    block: zodOptionalDdropdownSchema.nullable(),
    row: zodOptionalDdropdownSchema.nullable(),
    seat: zodOptionalDdropdownSchema.nullable(),
});

interface PriceError {
    value?: {
        message: string;
    };
    currency?: {
        message: string;
    };
}

export interface TicketDetailsFormValues {
    category: string;
    seatingPlanCategoryId: string;
    block?: AutoCompleteOption;
    row?: AutoCompleteOption;
    seat?: AutoCompleteOption;
    available: boolean;
    type: string;
    ticketFile?: TicketFile;
    purchasePrice?: Price;
    bookingOptionName: string;
    bookingOptionId: string;
}

interface Props {
    eventId: string;
    loading?: boolean;
    defaultValues?: TicketDetailsFormValues;
    standardTicket?: boolean;
    onFormSubmit: (values: TicketDetailsFormValues) => void;
    editMode: boolean;
    toggleEditMode: () => void;
    assignedRowId?: string | null;
    assignedSeat?: AutoCompleteOption;
    isContractTicket?: boolean;
    purchaseStatus: PURCHASE_STATUS;
    onLockStatusChange: (status: 'lock' | 'unlock') => void;
    isTicketLocked: boolean;
    isUpdatingLockedStatus: boolean;
}

export default function TicketDetailsForm({
    eventId,
    loading,
    defaultValues,
    standardTicket,
    onFormSubmit,
    onLockStatusChange,
    editMode,
    toggleEditMode,
    assignedRowId,
    assignedSeat,
    isContractTicket,
    purchaseStatus,
    isTicketLocked,
    isUpdatingLockedStatus = false,
}: Props) {
    const theme = useTheme();
    const classes = useStyles();

    const form = useForm<TicketDetailsFormValues>({
        mode: 'onChange',
        resolver: zodResolver(
            standardTicket ? ticketDetailsValidationSchema : contractTicketDetailsValidationSchema
        ),
    });

    const { watch, control, getValues, setValue, reset, handleSubmit } = form;

    useEffect(() => {
        if (!defaultValues) return;

        reset(defaultValues);
    }, [defaultValues, reset]);

    const block = watch('block');
    const row = watch('row');
    const seat = watch('seat');

    const seatingPlanCategoryName = getValues().category,
        seatingPlanCategoryId = getValues().seatingPlanCategoryId,
        type = getValues().type,
        available = getValues().available,
        purchasePrice = getValues().purchasePrice,
        bookingOptionName = getValues().bookingOptionName;

    return (
        <>
            <DictionaryGrid>
                <Grid item xs={4}>
                    Category
                </Grid>
                <Grid item xs={8}>
                    <span>{seatingPlanCategoryName}</span>
                </Grid>
                <Grid item xs={4}>
                    Booking Option
                </Grid>
                <Grid item xs={8}>
                    <span>{bookingOptionName}</span>
                </Grid>
                <Grid item xs={4}>
                    Block
                </Grid>
                <Grid item xs={8}>
                    {!editMode && <span>{block?.label}</span>}
                    {editMode && (
                        <BlocksSelectDataWrapper
                            name="block"
                            seatingPlanCategoryId={seatingPlanCategoryId}
                            value={block}
                            onChange={(option?: AutoCompleteOption) => {
                                setValue('block', option, { shouldValidate: true });
                                setValue('row', undefined, { shouldValidate: true });
                                setValue('seat', undefined, { shouldValidate: true });
                            }}
                            onSuccessfullyCreate={(option?: AutoCompleteOption) => {
                                setValue('block', option, { shouldValidate: true });
                                setValue('row', undefined, { shouldValidate: true });
                                setValue('seat', undefined, { shouldValidate: true });
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={4}>
                    Row
                </Grid>
                <Grid item xs={8}>
                    {!editMode && <span>{row?.label}</span>}
                    {editMode && (
                        <RowsSelectDataWrapper
                            name="row"
                            value={row}
                            seatingPlanCategoryId={seatingPlanCategoryId}
                            blockId={block?.value}
                            onChange={(option?: AutoCompleteOption) => {
                                setValue('row', option, { shouldValidate: true });
                                setValue('seat', undefined, { shouldValidate: true });
                            }}
                            onSuccessfullyCreate={(option?: AutoCompleteOption) => {
                                setValue('row', option, { shouldValidate: true });
                                setValue('seat', undefined, { shouldValidate: true });
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={4}>
                    Seat
                </Grid>
                <Grid item xs={8}>
                    {!editMode && <span>{seat?.label}</span>}
                    {editMode && (
                        <SeatsSelectDataWrapper
                            name="seat"
                            value={seat}
                            eventId={eventId}
                            rowId={row?.value}
                            assignedRowId={assignedRowId ?? undefined}
                            assignedSeat={assignedSeat}
                            onChange={(option?: AutoCompleteOption) =>
                                setValue('seat', option, { shouldValidate: true })
                            }
                            onSuccessfullyCreate={(option?: AutoCompleteOption) =>
                                setValue('seat', option, { shouldValidate: true })
                            }
                            disabled={!block?.value || !row?.value}
                        />
                    )}
                </Grid>
                <Grid item xs={4}>
                    Available
                </Grid>
                <Grid item xs={8}>
                    <span>{available ? 'Yes' : 'No'}</span>
                </Grid>
                <Grid item xs={4}>
                    type
                </Grid>
                <Grid item xs={8}>
                    <span>{type}</span>
                </Grid>
                <Grid item xs={4}>
                    Contract ticket
                </Grid>
                <Grid item xs={8}>
                    {isContractTicket && <CheckIcon success />}
                </Grid>
                <Grid item xs={4}>
                    Ticket Price
                </Grid>
                <Grid item xs={8}>
                    {!editMode && (
                        <>
                            {purchasePrice && (
                                <>
                                    <span className={classes.spacingLeft}>
                                        {formatPrice({
                                            currency: purchasePrice.currency,
                                            amount: Number(purchasePrice.value || 0),
                                        })}
                                    </span>
                                </>
                            )}
                        </>
                    )}
                    {editMode && (
                        <Controller
                            name="purchasePrice"
                            control={control}
                            render={({ field: { value }, fieldState: { error } }) => {
                                const price = value || {
                                    currency: defaultCurrency,
                                    value: 0,
                                };
                                const typedError = error as PriceError | undefined;
                                const hasConfirmedPurchase =
                                    purchaseStatus === PURCHASE_STATUS.Confirmed;

                                return (
                                    <>
                                        {renderPriceInput({
                                            price,
                                            onChange: (price: Price) =>
                                                setValue('purchasePrice', price, {
                                                    shouldValidate: true,
                                                }),
                                            currencyError: typedError?.currency?.message,
                                            valueError: typedError?.value?.message,
                                            currencyOptions: currencyOptions,
                                            disabled: isContractTicket,
                                        })}
                                        {!hasConfirmedPurchase && (
                                            <Grid item xs={12} style={{ paddingTop: 0 }}>
                                                <FormHelperText>
                                                    This ticket belongs to a purchase with status{' '}
                                                    {purchaseStatus}. You can only change the price
                                                    when the purchase has status{' '}
                                                    {PURCHASE_STATUS.Confirmed}
                                                </FormHelperText>
                                            </Grid>
                                        )}
                                    </>
                                );
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={4}>
                    Ticket File
                </Grid>
                <Grid item xs={8}>
                    <Controller
                        name={'ticketFile'}
                        control={control}
                        render={({ field: { value: file } }) => {
                            return (
                                <>
                                    {file && (
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            tinyChip
                                            label={file.name}
                                            icon={<AttachFileOutlinedIcon />}
                                        />
                                    )}
                                </>
                            );
                        }}
                    />
                </Grid>
                <Alert severity="info">
                    Only <strong>Lead</strong>, <strong>Senior</strong>, and{' '}
                    <strong>Administrator</strong> roles are able to modify the locked status of the
                    ticket.
                </Alert>
            </DictionaryGrid>
            <div className={classes.editText}>
                <FormButtons
                    buttons={[
                        {
                            disabled:
                                loading ||
                                isUpdatingLockedStatus ||
                                !UserPermissions.canModifyTicketLockedStatus(),
                            startIcon: isUpdatingLockedStatus && (
                                <CircularProgress size={theme.layout.loader.sizes.small} />
                            ),
                            children: isTicketLocked ? 'Unlock ticket' : 'Lock ticket',
                            onClick: () => {
                                isTicketLocked
                                    ? onLockStatusChange('unlock')
                                    : onLockStatusChange('lock');
                            },
                            color: isTicketLocked ? 'warning' : 'error',
                        },
                        {
                            disabled: loading || isUpdatingLockedStatus,
                            startIcon: loading && (
                                <CircularProgress size={theme.layout.loader.sizes.small} />
                            ),
                            children: editMode ? 'Save Ticket' : 'Edit ticket',
                            onClick: () => {
                                if (editMode) {
                                    handleSubmit(onFormSubmit)();
                                    return;
                                }

                                toggleEditMode();
                            },
                        },
                    ]}
                />
            </div>
        </>
    );
}
