import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { QUERY_KEY as ORGANIZERS_QUERY_KEY } from 'src/app/hooks/useOrganizers';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { TAG_TYPES } from 'src/data/enums/tagType';
import organizerService from 'src/data/services/organizerService';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import z from 'zod';
import { TagsAutocompleteFeature } from '../tags/TagsAutocompleteFeature';

interface Props {
    onSuccess?: () => void;
    organizerId?: string;
    disabled?: boolean;
}
const validationSchema = z.object({
    organizerName: z.string().min(1, 'Name is required'),
    tags: z.array(zodRequiredDropdownSelectSchema),
});

type CreateOrUpdateOrganizerFormSchema = z.infer<typeof validationSchema>;

const CreateOrUpdateOrganizerFeature = ({ onSuccess, organizerId, disabled }: Props) => {
    const form = useForm<CreateOrUpdateOrganizerFormSchema>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
    });

    const {
        control,
        formState: { isDirty },
        setValue,
        watch,
    } = form;

    const organizerName = watch('organizerName');
    const tags = watch('tags');
    const tagIds = tags?.map((t) => t.value);

    const queryClient = useQueryClient();

    const {
        isLoading,
        isError,
        data: organizerData,
        error,
    } = useQuery({
        queryKey: ['organizer', organizerId],
        queryFn: () => organizerService.getOrganizerById(organizerId ?? ''),
        enabled: !!organizerId,
    });

    const {
        mutate: createOrganizer,
        isLoading: isCreateLoading,
        isError: isCreateError,
        error: createError,
    } = useMutation({
        mutationFn: () => organizerService.createOrganizer(organizerName),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ORGANIZERS_QUERY_KEY] });
            onSuccess?.();
        },
    });

    const {
        mutate: updateOrganizer,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
    } = useMutation({
        mutationFn: () =>
            organizerService.updateOrganizer(organizerId ?? '', organizerName, tagIds),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ORGANIZERS_QUERY_KEY] });
            onSuccess?.();
        },
    });

    const onSubmit = useCallback(() => {
        if (organizerId) {
            return updateOrganizer();
        }

        return createOrganizer();
    }, [createOrganizer, organizerId, updateOrganizer]);

    useEffect(() => {
        if (!organizerData?.data) return;

        form.setValue('organizerName', organizerData.data.data.name);
        form.setValue(
            'tags',
            organizerData.data.data.tags?.map((t) => ({
                label: t.name,
                value: t.id,
            }))
        );

        // setValue(organizerData.data.data.name);
    }, [organizerData]);

    return (
        <>
            {(isCreateLoading || (isLoading && organizerId) || isUpdateLoading) && (
                <LoadingOverlay />
            )}
            {isCreateError && <Errors error={createError} />}
            {isUpdateError && <Errors error={updateError} />}
            {isError ? (
                <Errors error={error} />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ModalSubTitle>Name</ModalSubTitle>
                        <Controller
                            name="organizerName"
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Organizer name"
                                        disabled={disabled || isCreateLoading}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ModalSubTitle>Tags</ModalSubTitle>
                        <Controller
                            name="tags"
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <>
                                    <TagsAutocompleteFeature
                                        type={TAG_TYPES.OrganizerTag}
                                        onChange={onChange}
                                        isMulti
                                        name={name}
                                        value={value}
                                        placeholder="Select or create tag"
                                        onTagCreated={(option) => {
                                            if (value) {
                                                setValue('tags', [...value, option]);

                                                return;
                                            }

                                            setValue('tags', [option]);
                                        }}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel># of events</FormLabel>
                        <Input
                            name="eventsCount"
                            value={organizerData?.data?.data?.eventsCount}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormButtons
                            buttons={[
                                {
                                    disabled: isCreateLoading || isUpdateLoading || !isDirty,
                                    children: organizerId ? 'Update' : 'Create',
                                    onClick: () => onSubmit(),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default CreateOrUpdateOrganizerFeature;

const Errors = ({ error }: { error: unknown }) => {
    const errorsObject =
        error instanceof AxiosError &&
        isErrors(error.response?.data) &&
        error.response?.data.errors;

    if (errorsObject) {
        const errorMessages = Object.entries(errorsObject).map(([field, messages]) => {
            return messages.map((message: string) => {
                return (
                    <Alert severity="error" key={field}>
                        {field} - {message}
                    </Alert>
                );
            });
        });

        return <div>{errorMessages}</div>;
    }

    if (error instanceof AxiosError || error instanceof Error) {
        return <Alert severity="error">{error.message}</Alert>;
    }

    return <Alert severity="error">Unknown error</Alert>;
};

type ErrorResponseData = {
    errors: {
        [key: string]: string[];
    };
};

function isErrors(errorResponseData: unknown): errorResponseData is ErrorResponseData {
    if (typeof errorResponseData !== 'object' || errorResponseData === null) {
        return false;
    }

    if (!Object.prototype.hasOwnProperty.call(errorResponseData, 'errors')) {
        return false;
    }

    const errors = (errorResponseData as { errors: unknown }).errors;
    if (typeof errors !== 'object' || errors === null) {
        return false;
    }

    return Object.keys(errors).every(
        (key) =>
            typeof key === 'string' && Array.isArray((errors as { [key: string]: unknown })[key])
    );
}
