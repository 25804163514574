import compact from 'lodash/compact';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';
import { useFetchSeatingPlanCategories } from 'src/app/hooks/useFetchSeatingPlanCategories';
import { purchaseStatusOptions } from 'src/app/utilities/helpers/filter-options/purchase';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import FilterOption from 'src/data/api/common/FilterOption';
import {
    FilterAutoCompleteOption,
    FilterAutoCompleteOptions,
} from 'src/view/components/filters/AutoComplete/AutoComplete';
import useFetchEventBookingOptions from '../event-booking-options/hooks/use-fetch-event-booking-options';

export interface AdvancedEditPurchasesFilterFormValues {
    purchaseStatus?: FilterAutoCompleteOptions;
    eventId?: FilterAutoCompleteOption;
    seatingPlanCategoryId?: FilterAutoCompleteOptions;
    bookingOptionIds?: FilterAutoCompleteOptions;
}

interface AdvancedEditPurchasesFilterFormProps {
    defaultValues: AdvancedEditPurchasesFilterFormValues;
    seatingPlanCategoriesDropdownOptions: FilterAutoCompleteOptions;
    bookingOptionsDropdownOptions: FilterAutoCompleteOptions;
    onChangeFilterOptions: (options: FilterOption[]) => void;
}

export default function EventDetailsFilterFeature({
    defaultValues,
    seatingPlanCategoriesDropdownOptions,
    bookingOptionsDropdownOptions,
    onChangeFilterOptions,
}: AdvancedEditPurchasesFilterFormProps): JSX.Element {
    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();

    const form = useForm<AdvancedEditPurchasesFilterFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const { watch, reset, control, resetField, setValue } = form;
    const event = watch('eventId'); // event id has to be single {value: eventId, label: eventName}

    /** The variable name is kept as seatingPlanCategoryId for convenience of useQueryParams
     * Query params are formed as seatingPlanCategoryId=id1,id2,id3
     * */
    const seatingPlanCategoryId = watch('seatingPlanCategoryId');
    const bookingOptionIds = watch('bookingOptionIds');

    useEffect(() => {
        if (!event) {
            setValue('seatingPlanCategoryId', []);
            setValue('bookingOptionIds', []);

            return;
        }

        resetField('seatingPlanCategoryId');
        resetField('bookingOptionIds');
    }, [event]);

    useEffect(() => {
        const filters = compact([
            formatValuesToParams.formatOptionsToParam(
                'seatingPlanCategoryId',
                seatingPlanCategoryId
            ),
            formatValuesToParams.formatOptionsToParam('bookingOptionIds', bookingOptionIds),
            formatValuesToParams.formatOptionToParam('eventId', event),
        ]).filter((f) => f.value !== undefined && f.value !== '');

        onChangeFilterOptions(filters);
    }, [urlInvalidatedAt, JSON.stringify(defaultValues)]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const arrayOfFilters: FiltersArray = [
        {
            type: 'autocomplete',
            options: bookingOptionsDropdownOptions,
            name: 'bookingOptionIds',
            filterPlaceholderProps: {
                selectedText: 'Selected booking option(s)',
                placeholder: 'Booking Option',
            },
            isMulti: true,
            onChange: () => {
                setUrlInvalidatedAt(Date.now());
            },
        },
        {
            type: 'autocomplete',
            options: seatingPlanCategoriesDropdownOptions,
            name: 'seatingPlanCategoryId',
            filterPlaceholderProps: {
                selectedText: 'Selected category',
                placeholder: 'Seating Plan Category',
            },
            isMulti: true,
            onChange: () => {
                setUrlInvalidatedAt(Date.now());
            },
        },
    ];

    return <Filters control={control} filters={arrayOfFilters} />;
}

const usePurchaseEventSeatingPlanCategoryFilters = (
    eventId: string | undefined,
    filterOptions: FilterOption[]
) => {
    const { data: seatingPlanCategories, isLoading } = useFetchSeatingPlanCategories(eventId || '');

    const hasFetchedSeatingPlanCategories = !eventId || !isLoading;

    const seatingPlanCategoriesDropdownOptions: FilterAutoCompleteOptions = useMemo(() => {
        const seatingCategories = seatingPlanCategories?.data?.data?.categories || [];

        return seatingCategories.map(({ id, name }) => ({
            value: id,
            label: name,
        }));
    }, [seatingPlanCategories]);

    const defaultFilters = useMemo(
        () => ({
            seatingPlanCategoryId: tableFilterFormHelper.getInitialAutocompleteValues(
                'seatingPlanCategoryId',
                filterOptions,
                seatingPlanCategoriesDropdownOptions
            ),
        }),
        [seatingPlanCategoriesDropdownOptions, filterOptions]
    );

    return {
        defaultFilters,
        hasFetchedSeatingPlanCategories,
        seatingPlanCategoriesDropdownOptions,
    };
};

const usePurchaseEventBookingOptionFilters = (
    eventId: string | undefined,
    filterOptions: FilterOption[]
) => {
    const { data: bookingOptionsData, isLoading } = useFetchEventBookingOptions(eventId || '');

    const hasFetchedBookingOptions = !eventId || !isLoading;

    const bookingOptionsDropdownOptions: FilterAutoCompleteOptions = useMemo(() => {
        const seatingCategories = bookingOptionsData?.data?.data || [];

        return seatingCategories.map(({ id, name }) => ({
            value: id,
            label: name,
        }));
    }, [bookingOptionsData]);

    const defaultFilters = useMemo(
        () => ({
            bookingOptionIds: tableFilterFormHelper.getInitialAutocompleteValues(
                'bookingOptionIds',
                filterOptions,
                bookingOptionsDropdownOptions
            ),
        }),
        [bookingOptionsDropdownOptions, filterOptions]
    );

    return {
        defaultFilters,
        hasFetchedBookingOptions,
        bookingOptionsDropdownOptions,
    };
};

export const useEventDetailsFilterOptions = ({
    eventId,
    filterOptions,
}: {
    eventId: string | undefined;
    filterOptions: FilterOption[];
}) => {
    const {
        defaultFilters: seatingPlanCategoryValues,
        hasFetchedSeatingPlanCategories,
        seatingPlanCategoriesDropdownOptions,
    } = usePurchaseEventSeatingPlanCategoryFilters(eventId, filterOptions);

    const {
        defaultFilters: bookingOptionsValues,
        hasFetchedBookingOptions,
        bookingOptionsDropdownOptions,
    } = usePurchaseEventBookingOptionFilters(eventId, filterOptions);

    const hasFinishedFormingDefaultValues =
        hasFetchedSeatingPlanCategories && hasFetchedBookingOptions;

    const defaultValues = useMemo(
        () => ({
            ...seatingPlanCategoryValues,
            ...bookingOptionsValues,
            purchaseStatus: tableFilterFormHelper.getInitialAutocompleteValues(
                'purchaseStatus',
                filterOptions,
                purchaseStatusOptions
            ),
        }),
        [seatingPlanCategoryValues, filterOptions]
    );

    return {
        defaultValues,
        /** Default values of Seating Plan Categories are dynamic and conditional.
         * Hence we must wait for the assigning operations to finish.
         * Example: Event ID in the url? -> fetch seating plan categories for that event -> select the default value */
        hasFinishedFormingDefaultValues,
        seatingPlanCategoriesDropdownOptions,
        bookingOptionsDropdownOptions,
    };
};
