import { useCallback, useMemo } from 'react';
import OrderlineFiltersForm, {
    assignedStatusOptions,
    assignmentInstructionsOptions,
    orderInternalNotesOptions,
    quantityOptions,
    shippingStatusOptions,
    statusOptions,
} from 'src/app/components/forms/OrderlineFiltersForm';
import { sourceSystemOptions } from 'src/app/constants/constants/sourceSystem';
import { useFetchSeatingPlanCategories } from 'src/app/hooks/useFetchSeatingPlanCategories';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import FilterOption from 'src/data/api/common/FilterOption';
import { SeatingPlanCategory } from 'src/data/models/seating-plan-category/SeatingPlanCategory';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import { type FilterAutoCompleteOptions } from 'src/view/components/filters/AutoComplete/AutoComplete';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { useGetBookingOptionsDropdownOptions } from '../../features/event-booking-options/hooks/use-get-booking-options-dropdown-options';

interface Props {
    eventId: string;
    initialOptions: FilterOption[];
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    enableInitialSelection?: boolean;
    onChangeSearchTerm?: (q: string) => void;
    initialSearchTerm?: string;
}

export default function OrderlinesFiltersFormDataWrapper({
    eventId,
    initialOptions,
    onChangeFilterOptions,
    onChangeSearchTerm,
    initialSearchTerm,
}: Props) {
    const { data, isLoading, error: errors, isError } = useFetchSeatingPlanCategories(eventId);
    const { bookingOptionsDropdownOptions } = useGetBookingOptionsDropdownOptions(eventId);

    const { categoryOptions, assignedCategoryOptions } = useMemo(() => {
        const categoryOptions = toSeatingPlanCategoryFilterOptions(data?.data.data.categories);

        const assignedCategoryOptions = toSeatingPlanCategoryFilterOptions(
            data?.data.data.assignedCategories
        );

        return { categoryOptions, assignedCategoryOptions };
    }, [data]);

    const getOptions = useCallback(
        (optionProperty: string, autocompleteOptions: FilterAutoCompleteOptions) =>
            tableFilterFormHelper.getInitialAutocompleteValues(
                optionProperty,
                initialOptions,
                autocompleteOptions
            ),
        [initialOptions]
    );

    const formDefaultValues = useMemo(() => {
        if (!data) return {};

        return {
            category: getOptions('seatingPlanCategoryId', categoryOptions),
            bookingOptionIds: getOptions('bookingOptionIds', bookingOptionsDropdownOptions),
            assignedSeatingPlanCategoryId: getOptions(
                'assignedSeatingPlanCategoryId',
                assignedCategoryOptions
            ),
            status: getOptions('status', statusOptions),
            isAssigned: getOptions('isAssigned', assignedStatusOptions)?.[0],
            quantityParity: getOptions('quantityParity', quantityOptions)?.[0],
            sourceSystem: getOptions('sourceSystem', sourceSystemOptions)?.[0],
            shippingStatus: getOptions('shippingStatus', shippingStatusOptions),
            hasAssignmentInstructions: getOptions(
                'hasAssignmentInstructions',
                assignmentInstructionsOptions
            )?.[0],
            hasOrderInternalNotes: getOptions(
                'hasOrderInternalNotes',
                orderInternalNotesOptions
            )?.[0],
            searchTerm: '',
            orderId: initialOptions.find((o) => o.property === 'orderId')?.value,
        };
    }, [data, initialOptions, categoryOptions, getOptions, assignedCategoryOptions]);

    if (isLoading && !data) {
        return <LoadingOverlay />;
    }

    if (isError) {
        return <ErrorsList errors={parseErrors(errors)} />;
    }

    return (
        <OrderlineFiltersForm
            defaultValues={formDefaultValues}
            categoryOptions={categoryOptions.filter((category) => !category.isArchived)}
            bookingOptions={bookingOptionsDropdownOptions}
            assignedCategoryOptions={assignedCategoryOptions.filter(
                (category) => !category.isArchived
            )}
            assignmentOptions={assignedStatusOptions}
            statusOptions={statusOptions}
            shippingStatusOptions={shippingStatusOptions}
            quantityOptions={quantityOptions}
            onChangeFilterOptions={onChangeFilterOptions}
            loading={isLoading}
            onChangeSearchTerm={onChangeSearchTerm}
            initialSearchTerm={initialSearchTerm}
        />
    );
}

export function toSeatingPlanCategoryFilterOptions(
    categories: SeatingPlanCategory[] | undefined
): FilterAutoCompleteOptions {
    return (categories ?? []).map((c) => ({
        label: c.name,
        value: c.id,
        isArchived: c.isArchived,
    }));
}
