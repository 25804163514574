import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
    defaultTicketsSentEarliestDays,
    defaultTicketsSentLatestDays,
} from 'src/app/constants/constants/form/events';
import mapEventDetailsToFormValues from 'src/app/utilities/mappers/mapEventDetailsToFormValues';
import { EVENT_TYPE, EventDetails } from 'src/data/models/events/event';
import {
    type AutoCompleteOption,
    type AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';
import * as Yup from 'yup';

export interface CreateEventFormValues {
    eventType?: AutoCompleteOption;
    contestants?: AutoCompleteOptions;
    artists?: AutoCompleteOptions;
    matches?: { id: string; name: string }[];
    homeTeam?: AutoCompleteOption;
    awayTeam?: AutoCompleteOption;
    eventName?: string;
    startDate?: string;
    endDate?: string;
    dateConfirmed: boolean;
    eventCategory?: AutoCompleteOption;
    eventTags?: AutoCompleteOptions;
    description?: string;
    series?: AutoCompleteOption;
    organizer?: AutoCompleteOption;
    venue?: AutoCompleteOption;
    seatingPlan?: AutoCompleteOption;
    active: boolean;
    ticketsSentEarliestDays?: number;
    ticketsSentLatestDays?: number;
    allowAwayFans: boolean;
    blacklistedCountries?: AutoCompleteOptions;
    blacklistedNationalities?: AutoCompleteOptions;
    requireFullCustomerAddress: boolean;
    hasMultipleBookingOptions: boolean;
}

export type UseCreateEventFormReturn = ReturnType<typeof useCreateEventForm>;

const isDefinedNumber = (value?: null | number): value is number => {
    return value !== null && value !== undefined && typeof value === 'number';
};

const validationSchema = Yup.object({
    eventType: Yup.object().required('Please select an event type'),
    homeTeam: Yup.object().when('eventType', {
        is: (eventType?: AutoCompleteOption) => eventType?.value === EVENT_TYPE.TeamSports,
        then: Yup.object().required('Please select a team'),
        otherwise: Yup.object().notRequired(),
    }),
    awayTeam: Yup.object()
        .when('eventType', {
            is: (eventType?: AutoCompleteOption) => eventType?.value === EVENT_TYPE.TeamSports,
            then: Yup.object().required('Please select a team'),
            otherwise: Yup.object().notRequired(),
        })
        .test('match', 'Away team cannot be equal to home team', function (awayTeam) {
            if (this.parent.eventType?.value !== EVENT_TYPE.TeamSports) return true;

            return awayTeam?.value !== this.parent.homeTeam?.value;
        }),
    eventName: Yup.string().required('Please fill in an event name'),
    eventCategory: Yup.object().required('Please fill in an event category'),
    series: Yup.object().required('Please select a series'),
    organizer: Yup.object().required('Please select an organizer'),
    venue: Yup.object().required('Please select a venue'),
    seatingPlan: Yup.object().required('Please select a seating plan'),
    startDate: Yup.mixed()
        .required('Please select a start date')
        .test('is-valid-date', 'Invalid date format', (value) => {
            return moment(value, moment.ISO_8601, true).isValid();
        })
        .test('is-utc', 'Date must be in UTC', (value) => {
            const date = moment(value);
            return date.isValid() && date.isUTC();
        })
        .test('is-future', 'Please select a date in the future', (value) => {
            const date = moment(value);
            return date.isValid() && date.isAfter(moment.utc());
        }),
    endDate: Yup.mixed()
        .nullable() // Allows the field to be null or undefined
        .transform((_, originalValue) => {
            // Transform invalid dates into null
            return moment(originalValue, moment.ISO_8601, true).isValid()
                ? moment.utc(originalValue).toISOString()
                : null;
        })
        .test('is-utc', 'Date must be in UTC', (value) => {
            return value ? moment(value).isUTC() : true;
        })
        .test(
            'end_date_is_after_start',
            'The end date should be after the start date',
            function (value) {
                const { startDate } = this.parent;
                if (!value || !startDate) return true; // Skip if either is null
                return moment.utc(value).isAfter(moment.utc(startDate));
            }
        ),
    ticketsSentEarliestDays: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .test(
            'isLessThanSentEarliest',
            'This value should be greater than tickets sent latest',
            function (value) {
                const { ticketsSentLatestDays } = this.parent;

                if (!isDefinedNumber(value)) {
                    value = defaultTicketsSentEarliestDays;
                }

                if (!isDefinedNumber(ticketsSentLatestDays)) {
                    this.parent.ticketsSentLatestDays = defaultTicketsSentLatestDays;
                }

                return value >= this.parent.ticketsSentLatestDays && value >= 0;
            }
        ),
    ticketsSentLatestDays: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .test(
            'isLessThanSentEarliest',
            'This value should be less than tickets sent earliest',
            function (value) {
                const { ticketsSentEarliestDays } = this.parent;

                if (!isDefinedNumber(value)) {
                    value = defaultTicketsSentLatestDays;
                }

                if (!isDefinedNumber(ticketsSentEarliestDays)) {
                    this.parent.ticketsSentEarliestDays = defaultTicketsSentEarliestDays;
                }

                return value <= this.parent.ticketsSentEarliestDays && value >= 0;
            }
        ),
});

export function useCreateEventForm(eventDetails?: EventDetails) {
    const defaultValues = useMemo(() => {
        return eventDetails
            ? mapEventDetailsToFormValues(eventDetails)
            : {
                  allowAwayFans: true,
                  requireFullCustomerAddress: false,
                  ticketsSentEarliestDays: defaultTicketsSentEarliestDays,
                  ticketsSentLatestDays: defaultTicketsSentLatestDays,
              };
    }, [eventDetails]);

    return useForm<CreateEventFormValues>({
        mode: 'onChange',
        // TODO: fix this ts error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
}
