import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Toaster from 'src/app/utilities/helpers/Toaster';
import purchaseService from 'src/data/services/purchaseService';
import { GET_PURCHASE_BY_ID_QUERY_KEY } from './useFetchPurchaseById';
import { PURCHASES_QUERY } from './useFetchPurchases';

type TData = Awaited<ReturnType<typeof purchaseService.completePurchases>>;

export const useCompletePurchases = (
    onSuccess?: (data: TData) => unknown,
    onError?: () => void
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (purchaseIds: string[]) => purchaseService.completePurchases(purchaseIds),
        onSuccess: (data) => {
            onSuccess?.(data);
            Toaster.toast('Successfully completed the purchases!', {
                variant: 'success',
            });
            queryClient.invalidateQueries({ queryKey: [GET_PURCHASE_BY_ID_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [PURCHASES_QUERY] });
        },
        onError: (e: AxiosError) => {
            onError?.();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const errorMessage = e?.response?.data?.errors[0]?.detail;
            Toaster.toast(errorMessage, {
                variant: 'error',
            });
            queryClient.invalidateQueries({ queryKey: [GET_PURCHASE_BY_ID_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [PURCHASES_QUERY] });
        },
    });
};
