/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    Alert,
    FormControlLabel,
    Grid,
    Table as TableMui,
    TableBody as TableMuiBody,
    TableProps as TableMuiProps,
    TableRowProps,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, DragStart, DragUpdate, Droppable } from 'react-beautiful-dnd';
import useEffectSkipMount from 'src/shared/useEffectSkipMount';
import Checkbox from 'src/view/components/checkbox/Checkbox';
import TablePagination from 'src/view/components/table-pagination/TablePagination';
import TableToolbar, {
    TableAction,
    TableSplitAction,
} from 'src/view/components/table-toolbar/TableToolbar';
import TableCell from 'src/view/components/table/table-cell/TableCell';
import TableHead from 'src/view/components/table/table-head/TableHead';
import TableRow from 'src/view/components/table/table-row/TableRow';
import {
    NewTableColumn,
    TABLE_CELL_HEIGHT,
    TABLE_CELL_WIDTH,
    TABLE_ROW_TYPE,
    TableColumnSorting,
    TableColumnSortingDirection,
    TablePaginationData,
} from 'src/view/components/table/table/Types';
import TableColumnSortingIconButton from 'src/view/components/table/TableColumnSortingIconButton/TableColumnSortingIconButton';

export interface RowProps {
    type: TABLE_ROW_TYPE;
}

export interface RowIdResolver<T = Record<string, unknown>> {
    position?: number;
    id: string;
    data?: T;
}

export interface TableProps<TData, TDataResolver = Record<string, unknown>> extends TableMuiProps {
    data?: TData[];
    columns?: NewTableColumn<TData>[];
    rowProps?: (rowData: TData, previousRow?: TData) => RowProps;
    onSortingChange?: (sorting: TableColumnSorting[]) => void;
    defaultSorting?: TableColumnSorting[];
    enableMultiColumnSorting?: boolean;
    pagination?: TablePaginationData;
    loading?: boolean;
    minWidth?: number;
    maxWidth?: CSSProperties['maxWidth'];
    maxHeight?: number;
    variant?: 'primary';
    rowIdResolver: (rowData: TData) => RowIdResolver<TDataResolver>;
    enableCheckboxes?: boolean;
    onChangeSelectedRows?: (selectedRows: RowIdResolver<TDataResolver>[]) => void;
    initialSelectedRows?: RowIdResolver<TDataResolver>[];
    noCellPadding?: boolean;
    isActiveRow?: (rowData: TData) => boolean;
    onClickRow?: (rowData: TData) => void;
    rowHeight?: TABLE_CELL_HEIGHT;
    highlightRow?: (rowData: TData) => boolean;
    warningRow?: (rowData: TData) => boolean;
    enableDragAndDrop?: boolean;
    onChangeRowOrder?: (rowData: TData[]) => void;
    tableToolbarActions?: (selectedRows: RowIdResolver<TDataResolver>[]) => TableAction[];
    tableToolbarSplitActions?: (selectedRows: RowIdResolver<TDataResolver>[]) => TableSplitAction[];
    disableCheckbox?: (rowData: TData) => boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    table: { borderCollapse: 'collapse' },
    minWidth: (props: Partial<TableProps<unknown>>) => ({
        minWidth: props.minWidth,
    }),
    maxHeight: (props: Partial<TableProps<unknown>>) => ({
        maxHeight: props.maxHeight,
    }),
    maxWidth: (props: Partial<TableProps<unknown>>) => ({
        maxWidth: props.maxWidth,
    }),
    tableHeadCell: (props: Partial<TableProps<unknown>>) => {
        let primaryObject = {};

        if (props.variant === 'primary') {
            primaryObject = {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            };
        }

        return {
            ...primaryObject,
            height: '1px',
        };
    },
    innerTableHeadCell: (props: Partial<TableProps<unknown>>) => {
        let height = '36px';

        switch (props.rowHeight) {
            case TABLE_CELL_HEIGHT.LARGE:
                height = '48px';
                break;
            case TABLE_CELL_HEIGHT.SMALL:
                height = '24px';
                break;
        }

        return {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 0,
            height: '100%',
            minHeight: height,
            backgroundColor: theme.colors.bluishGrey,
            paddingTop: props.noCellPadding ? 0 : theme.spacing(0.5),
            paddingBottom: props.noCellPadding ? 0 : theme.spacing(0.5),
            paddingLeft: props.noCellPadding ? 0 : theme.spacing(1),
            paddingRight: props.noCellPadding ? 0 : theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
        };
    },
    columnHeader: {
        padding: theme.spacing(1, 2),
        'font-weight': theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    tableBody: {
        position: 'relative',
        background: theme.colors.white,
        border: `1px solid ${theme.palette.grey.A200}`,
    },
    tableBodyCell: () => ({
        wordBreak: 'break-word',
        padding: 0,
        '&:first-child': {
            borderLeft: `1px solid ${theme.palette.grey.A200}`,
        },
        '&:last-child': {
            borderRight: `1px solid ${theme.palette.grey.A200}`,
        },
    }),
    truncate: {
        '& p, span': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 1 /* number of lines to show */,
            lineClamp: 1,
            '-webkit-box-orient': 'vertical',
        },
        '& div': {
            minWidth: 0,
        },
    },
    innerBodyCell: (props: Partial<TableProps<unknown>>) => {
        let height = '36px';

        switch (props.rowHeight) {
            case TABLE_CELL_HEIGHT.EXTRA_LARGE:
                height = '68px';
                break;
            case TABLE_CELL_HEIGHT.LARGE:
                height = '48px';
                break;
            case TABLE_CELL_HEIGHT.SMALL:
                height = '24px';
                break;
            case TABLE_CELL_HEIGHT.NONE:
                height = 'unset';
                break;
        }

        return {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            height,
            paddingTop: props.noCellPadding ? 0 : theme.spacing(0.5),
            paddingBottom: props.noCellPadding ? 0 : theme.spacing(0.5),
            paddingLeft: props.noCellPadding ? 0 : theme.spacing(1),
            paddingRight: props.noCellPadding ? 0 : theme.spacing(1),
        };
    },
    tableBodyCellAlert: {
        padding: 0,
    },
    row: {
        backgroundColor: theme.colors.white,
        borderBottom: `1px solid ${theme.palette.grey.A400}`,
    },
    highlighted: {
        background: theme.palette.primary.light,
    },
    warningRow: {
        background: theme.colors.paleOrange,
    },
    cancelledRow: {
        background: `${theme.colors.lightestRed} !important`,
        '& td': {
            color: theme.palette.error.main,
            '&:first-of-type': {
                borderLeft: '4px inset red',
            },
            '& div[class*="text"], & div[class*="grey"]': {
                color: theme.palette.error.main,
            },
            '& span[class*="divider"]': {
                borderColor: theme.palette.error.light,
            },
        },
    },
    colored: {
        backgroundColor: theme.colors.lightBlue,
    },
    transparent: {
        backgroundColor: theme.colors.white,
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        textAlign: 'center',
        color: theme.colors.white,
        paddingTop: theme.spacing(2),
    },
    center: {
        margin: '0 auto',
        '& > *': {
            margin: '0 auto',
        },
    },
    pagination: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    columnSubHeader: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        backgroundColor: theme.colors.bluishGrey,
        'font-weight': theme.typography.fontWeightBold,
    },
    tableHeadRow: {
        backgroundColor: theme.colors.white,
    },
    tableContainer: {
        width: '100%',
        overflowX: 'scroll',
        '&::-webkit-scrollbar-track': {
            background: theme.colors.white,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.colors.mediumGrey,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: theme.colors.grey,
            cursor: 'pointer',
        },
        '& > .MuiTable-root': {
            borderCollapse: 'inherit',
        },
    },
    alignToCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
    },
    checkboxToolbar: {
        minHeight: '50px',
        display: 'flex',
        justifyContent: 'flex-start',
        height: '100%',
        alignItems: 'center',
        padding: '0 16px',
    },
    spacingLeft: {
        marginLeft: theme.spacing(2),
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            flex: 1,
        },
    },
    activeRow: {
        background: `${theme.palette.primary.light} !important`,
    },
    clickable: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    emptyTableRow: {
        width: '100%',
    },
    totalBarCell: {
        fontWeight: 'bold',
    },
    tableTotalRow: {
        background: theme.colors.bluishGrey,
    },
    alignLeft: {
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
    alignCenter: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    alignRight: {
        justifyContent: 'flex-end',
        textAlign: 'right',
    },
}));

export default function Table<TData, TDataResolver = Record<string, unknown>>({
    minWidth,
    maxHeight,
    variant,
    onSortingChange,
    defaultSorting = [],
    columns,
    rowProps,
    data,
    loading,
    enableMultiColumnSorting = false,
    pagination,
    rowIdResolver,
    enableCheckboxes = false,
    onChangeSelectedRows,
    initialSelectedRows = [],
    noCellPadding,
    isActiveRow,
    onClickRow,
    highlightRow,
    maxWidth,
    onChangeRowOrder,
    enableDragAndDrop = false,
    tableToolbarActions,
    tableToolbarSplitActions,
    warningRow,
    disableCheckbox,
    ...props
}: TableProps<TData, TDataResolver>): JSX.Element {
    const classes = useStyles({
        minWidth,
        maxHeight,
        variant,
        noCellPadding,
        maxWidth,
        ...props,
    });
    const theme = useTheme();

    const [sorting, setSorting] = useState<TableColumnSorting[]>(defaultSorting);
    const [shiftPressed, setShiftPressed] = useState(false);
    const [firstSelectedRow, setFirstSelectedRow] = useState<
        RowIdResolver<TDataResolver> | undefined
    >(undefined);
    const [selectedRows, setSelectedRows] =
        useState<RowIdResolver<TDataResolver>[]>(initialSelectedRows);

    const rowsRef = useRef(new Map());

    useEffect(() => {
        const shiftPressHandler = (e: KeyboardEvent) => {
            if (e.key === 'Shift') setShiftPressed(true);
        };

        const shiftReleasedHandler = () => {
            setShiftPressed(false);
            setFirstSelectedRow(undefined);
        };

        document.addEventListener('keydown', shiftPressHandler);
        document.addEventListener('keyup', shiftReleasedHandler);

        return () => {
            document.removeEventListener('keydown', shiftPressHandler);
            document.removeEventListener('keyup', shiftReleasedHandler);
        };
    }, []);

    useEffect(() => {
        if (firstSelectedRow) {
            setFirstSelectedRow(undefined);
        }
    }, [data]);

    useEffectSkipMount(() => {
        if (!onSortingChange) return;
        onSortingChange(sorting);
    }, [sorting]);

    useEffect(() => {
        setSelectedRows(initialSelectedRows);
    }, [`${initialSelectedRows}`]);

    const handleSortingClick = (column: string, direction: TableColumnSortingDirection) => {
        const clearedSorting = enableMultiColumnSorting
            ? sorting.filter((s) => s.column !== column)
            : [];

        if (direction === null) {
            setSorting([{ column, direction: null }]);

            return;
        }

        setSorting([...clearedSorting, { column, direction }]);

        return;
    };

    const reorder = (initialOrder: TData[], startIndex: number, endIndex: number) => {
        const result = initialOrder;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result || initialOrder;
    };

    const onBeforeDragStart = (dragStart: DragStart) => {
        const row = rowsRef.current.get(dragStart.source.index);
        applyDraggingStyles(row);
    };

    const applyDraggingStyles = (row: HTMLElement) => {
        row.style.boxShadow = 'rgba(0, 0, 0, 0.24) 0px 0px 8px';
        lockRowCellsDimensions(row);
    };

    const resetDraggingStyles = (row: HTMLElement) => {
        row.style.boxShadow = 'initial';

        for (let index = 0; index < row.children.length; index++) {
            const cell = row.children[index] as HTMLElement;
            cell.style.width = `initial`;
        }
    };

    const lockRowCellsDimensions = (row: HTMLElement) => {
        for (let index = 0; index < row.children.length; index++) {
            const cell = row.children[index] as HTMLElement;
            const initialCellWidth = cell?.getBoundingClientRect().width;
            cell.style.width = `${initialCellWidth}px`;
        }
    };

    const DraggableTableRow =
        (index: number) =>
        (props: TableRowProps): JSX.Element => {
            return (
                <Draggable
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={!enableDragAndDrop}
                >
                    {(provided) => {
                        return (
                            <TableRow
                                ref={(ref) => {
                                    if (ref) {
                                        rowsRef.current.set(index, ref);
                                    } else {
                                        rowsRef.current.delete(index);
                                    }

                                    return provided.innerRef(ref);
                                }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                {...props}
                            >
                                {props.children}
                            </TableRow>
                        );
                    }}
                </Draggable>
            );
        };

    const onDragEnd = (result: DragUpdate) => {
        if (!result.source || !result.destination) return;
        const row = rowsRef.current.get(result.source.index);
        resetDraggingStyles(row);

        const reorderedData = reorder(data || [], result.source.index, result.destination?.index);
        onChangeRowOrder?.(reorderedData);
    };

    function isRowSelected(id: string, selectedRows: RowIdResolver<TDataResolver>[]): boolean {
        return selectedRows.filter((row) => row.id === id).length === 1;
    }

    function allRowsSelected(selectedRows: RowIdResolver<TDataResolver>[]): boolean {
        if (!data || !rowIdResolver) return false;

        const currentDataIds = data.map((row) => rowIdResolver(row).id);

        return (
            selectedRows.filter((row) => currentDataIds.includes(row.id)).length ===
            currentDataIds.length
        );
    }

    function isAnyRowSelected(selectedRows: RowIdResolver<TDataResolver>[]): boolean {
        if (!data || !rowIdResolver) return false;

        const currentDataIds = data.map((row) => rowIdResolver(row).id);
        const selectedRowsIds = selectedRows.filter((row) => currentDataIds.includes(row.id));

        return selectedRowsIds.length > 0 && selectedRowsIds.length < data.length;
    }

    const checkboxSelectionPositionIdentifier = useMemo(() => {
        return (pagination?.currentPage || 1) * (data?.length || 1000);
    }, [pagination?.currentPage, data?.length]);

    function handleChangeRowSelection(
        event: React.ChangeEvent<HTMLInputElement>,
        row: RowIdResolver<TDataResolver>,
        rowIndex: number
    ): void {
        if (!enableCheckboxes) return;

        const selectRow = event.target.checked;
        interface RowWithIndexType extends RowIdResolver<TDataResolver> {
            position: number;
        }
        const rowWithIndex: RowWithIndexType = {
            ...row,
            position: checkboxSelectionPositionIdentifier + rowIndex,
        };

        if (selectRow) {
            const newSelection = selectedRows.concat(rowWithIndex);

            const sortedNewSelection = newSelection.sort((a, b) => a.position! - b.position!);

            setSelectedRows(sortedNewSelection);
            onChangeSelectedRows?.(sortedNewSelection);

            return;
        }

        const newSelection = selectedRows.filter((selectedRow) => selectedRow.id !== row.id);
        setSelectedRows(newSelection);
        onChangeSelectedRows?.(newSelection);
    }

    function handleChangeMultipleRowsSelection(
        event: React.ChangeEvent<HTMLInputElement>,
        row: RowIdResolver<TDataResolver>,
        rowId: number
    ) {
        if (!enableCheckboxes) return;

        if (!firstSelectedRow) {
            handleChangeRowSelection(event, row, rowId);

            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function hasIdProperty(obj: any): obj is { id: any } {
            return 'id' in obj;
        }

        const firstPressedWithShiftIndex = data?.findIndex(
            (r) => hasIdProperty(r) && r.id === firstSelectedRow?.id
        );
        const lastPressedWithShiftIndex = data?.findIndex(
            (r) => hasIdProperty(r) && r.id === row.id
        );

        if (lastPressedWithShiftIndex === undefined || firstPressedWithShiftIndex === undefined) {
            return;
        }

        let dataToBeSelected: TData[] = [];

        if (lastPressedWithShiftIndex < firstPressedWithShiftIndex) {
            dataToBeSelected = _.slice(
                data,
                lastPressedWithShiftIndex,
                firstPressedWithShiftIndex + 1
            );
        } else {
            dataToBeSelected = _.slice(
                data,
                firstPressedWithShiftIndex + 1,
                lastPressedWithShiftIndex + 1
            );
        }
        const rowsToAdd = dataToBeSelected?.map((r) => {
            return { ...rowIdResolver(r), position: checkboxSelectionPositionIdentifier + rowId };
        });

        const newSelectedRows = [...selectedRows, ...rowsToAdd];
        if (!rowsToAdd) return;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const sortedNewSelection = newSelectedRows.sort((a, b) => a.position! - b.position!);
        const filteredSelectedRows = _.uniqBy(sortedNewSelection, (r) => r.id);

        setSelectedRows(filteredSelectedRows);
        onChangeSelectedRows?.(filteredSelectedRows);
    }

    function handleChangeHeadRowSelection(event: React.ChangeEvent<HTMLInputElement>): void {
        if (!data || !enableCheckboxes) return;

        const selectAllRows = event.target.checked;

        if (selectAllRows === true && !isAnyRowSelected(selectedRows)) {
            const currentDataRows = data
                .filter((row) => !disableCheckbox?.(row))
                .map((row) => rowIdResolver(row));
            const newSelection = selectedRows.concat(currentDataRows);

            setSelectedRows(newSelection);
            onChangeSelectedRows?.(newSelection);

            return;
        }

        const currentDataIds = data.map((row) => rowIdResolver(row).id);
        const newSelection = selectedRows.filter((row) => !currentDataIds.includes(row.id));

        setSelectedRows(newSelection);
        onChangeSelectedRows?.(newSelection);
    }

    function renderHeadRowCheckboxCell(): JSX.Element {
        if (data?.length === 0 || !enableCheckboxes) return <></>;

        return (
            <TableCell
                key={Math.random()}
                className={classes.tableHeadCell}
                style={{
                    width: TABLE_CELL_WIDTH.TINY,
                    padding: 0,
                }}
            >
                <div className={classes.innerTableHeadCell}>
                    <Grid container className={classes.maxHeight}>
                        <Grid item xs={12}>
                            <div
                                className={classes.columnSubHeader}
                                style={{ justifyContent: 'center' }}
                            >
                                <FormControlLabel
                                    className={classes.alignToCenter}
                                    label=""
                                    control={
                                        <Checkbox
                                            checked={allRowsSelected(selectedRows)}
                                            indeterminate={isAnyRowSelected(selectedRows)}
                                            onChange={handleChangeHeadRowSelection}
                                            disabled={!data || data.length === 0}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                        />
                                    }
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </TableCell>
        );
    }

    function renderRowCheckboxCell(rowData: TData, rowIndex: number): JSX.Element {
        if (!enableCheckboxes) return <></>;

        const rowResolver = rowIdResolver(rowData);

        return (
            <TableCell key={`cell-${rowResolver.id}`} className={classes.tableBodyCell}>
                <FormControlLabel
                    className={classes.alignToCenter}
                    label=""
                    control={
                        <Checkbox
                            checked={isRowSelected(rowResolver.id, selectedRows)}
                            disabled={disableCheckbox?.(rowData)}
                            onChange={(event) => {
                                if (!shiftPressed) {
                                    setFirstSelectedRow(rowResolver);
                                }

                                if (shiftPressed) {
                                    handleChangeMultipleRowsSelection(event, rowResolver, rowIndex);

                                    return;
                                }

                                handleChangeRowSelection(event, rowResolver, rowIndex);
                            }}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                        />
                    }
                />
            </TableCell>
        );
    }

    const dataIsEmpty = data?.length === 0 && !loading;

    const emptyTableRow = (
        <tr>
            <td>
                <Alert severity="info" className={classes.emptyTableRow}>
                    No data found
                </Alert>
            </td>
        </tr>
    );

    const getAlignmentClass = (align?: 'left' | 'center' | 'right') => {
        switch (align) {
            case 'center':
                return classes.alignCenter;
            case 'right':
                return classes.alignRight;
            default:
                return classes.alignLeft;
        }
    };

    const renderHeadRow = (columns: NewTableColumn<TData>[]): JSX.Element => {
        const formatKeyColumnTitle = (key?: string) => {
            if (!key) {
                return '';
            }

            return key.charAt(0).toUpperCase() + key.slice(1);
        };

        return (
            <TableRow className={classes.tableHeadRow}>
                {renderHeadRowCheckboxCell()}
                {columns.map((col, i) => {
                    if (col.hidden) return;

                    return (
                        <TableCell
                            key={`th-cell-${i}`}
                            className={classNames(
                                classes.tableHeadCell,
                                getAlignmentClass(col.align)
                            )}
                            style={{
                                width: col.width ? col.width : TABLE_CELL_WIDTH.MEDIUM,
                                padding: 0,
                            }}
                        >
                            <div className={classes.innerTableHeadCell}>
                                {!!col.columnHeader && (
                                    <div className={classes.columnHeader}>{col.columnHeader}</div>
                                )}
                                <Grid container style={{ height: '100%' }}>
                                    <Grid item xs={12}>
                                        <div
                                            className={classNames(
                                                classes.columnSubHeader,
                                                getAlignmentClass(col.titleAlign)
                                            )}
                                        >
                                            {col.title || formatKeyColumnTitle(col.key?.toString())}
                                            {col.key !== undefined && col.isSortable === true && (
                                                <TableColumnSortingIconButton
                                                    currentDirection={
                                                        sorting.find(
                                                            (s) =>
                                                                s.column === col.sortingKey ||
                                                                s.column === col.key
                                                        )?.direction || null
                                                    }
                                                    handleDirectionChange={(direction) => {
                                                        col.key &&
                                                            handleSortingClick(
                                                                col.sortingKey || col.key,
                                                                direction
                                                            );
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    };

    const renderRows = (): JSX.Element | JSX.Element[] => {
        if (dataIsEmpty) {
            return emptyTableRow;
        }

        if (columns && data) {
            const previousRowData: TData | undefined = undefined;
            const renderedRows: JSX.Element[] = [];

            const renderCell = (
                column: NewTableColumn<TData>,
                rowData: TData,
                rowIndex: number
            ): JSX.Element | string | number => {
                if (column.cellRenderer) {
                    return column.cellRenderer(rowData, previousRowData, rowIndex);
                }

                if (!column.key) {
                    return column.title || '';
                }

                const key = column.key as keyof TData;

                const value = rowData[key];
                const valueType = typeof value;

                switch (valueType) {
                    case 'string':
                    case 'number':
                        return value + '';
                    case 'boolean':
                        return value ? 'yes' : 'no';
                    default:
                        return '';
                }
            };

            const getColoredCellStyles = (column: NewTableColumn<TData>, columnIndex: number) => {
                if (!column.colored) return;

                if (column.colored && column.colored.mode === 'all') {
                    return {
                        background: 'rgba(0, 0, 0, 0.02)',
                        borderLeft: `1px solid ${theme.colors.lightGrey}`,
                        borderRight: `1px solid ${theme.colors.lightGrey}`,
                    };
                }

                if (columnIndex % 2) {
                    return {
                        background: 'rgba(0, 0, 0, 0.02)',
                        borderLeft: `1px solid ${theme.colors.lightGrey}`,
                        borderRight: `1px solid ${theme.colors.lightGrey}`,
                    };
                } else {
                    return {
                        background: 'transparent',
                        borderLeft: 'none',
                        borderRight: 'none',
                    };
                }
            };

            data.forEach((rowData: TData, rowIndex: number) => {
                const typeIsDanger =
                    rowProps && rowProps(rowData).type === TABLE_ROW_TYPE.danger ? true : false;

                renderedRows.push(
                    <TableRow
                        hover
                        key={`table-row-${rowIndex}`}
                        className={classNames(
                            classes.row,
                            warningRow?.(rowData) && classes.warningRow,
                            typeIsDanger && classes.cancelledRow,
                            isActiveRow?.(rowData) && classes.activeRow,
                            onClickRow && classes.clickable,
                            highlightRow?.(rowData) && classes.highlighted
                        )}
                        component={DraggableTableRow(rowIndex)}
                        onClick={() => onClickRow?.(rowData)}
                    >
                        {renderRowCheckboxCell(rowData, rowIndex)}
                        {columns.map((column, columnIndex) => {
                            if (column.hidden) return;

                            return (
                                <TableCell
                                    key={`cell-${columnIndex}`}
                                    className={classNames(
                                        classes.tableBodyCell,
                                        column.truncate && classes.truncate,
                                        getAlignmentClass(column.align)
                                    )}
                                    style={{
                                        ...getColoredCellStyles(column, columnIndex),
                                        width: column.width
                                            ? column.width
                                            : TABLE_CELL_WIDTH.MEDIUM,
                                    }}
                                >
                                    <div
                                        className={classNames(
                                            classes.innerBodyCell,
                                            getAlignmentClass(column.align)
                                        )}
                                    >
                                        {renderCell(column, rowData, rowIndex)}
                                    </div>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            });

            const hasTotals = _.find(columns, (c) => !!c.total);

            if (hasTotals) {
                renderedRows.push(
                    <TableRow
                        key={`table-totals-row`}
                        className={classes.tableTotalRow}
                        component={DraggableTableRow(0)}
                    >
                        {columns.map((column, columnIndex) => {
                            if (column.hidden) return;

                            return (
                                <TableCell
                                    key={`cell-${columnIndex}`}
                                    className={classNames(
                                        classes.tableBodyCell,
                                        classes.totalBarCell,
                                        column.truncateTotal && classes.truncate,
                                        getAlignmentClass(column.align)
                                    )}
                                    style={{
                                        ...getColoredCellStyles(column, columnIndex),
                                        width: column.width
                                            ? column.width
                                            : TABLE_CELL_WIDTH.MEDIUM,
                                    }}
                                >
                                    <div className={classes.innerBodyCell}>
                                        {column.total || ''}
                                    </div>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            }

            return renderedRows;
        }

        return emptyTableRow;
    };

    const renderedRows = useMemo(() => renderRows(), [data, columns, selectedRows, shiftPressed]);

    if (data && data.length === 0 && !loading) {
        return (
            <TableMui>
                <TableMuiBody className={classes.tableBody}>{emptyTableRow}</TableMuiBody>
            </TableMui>
        );
    }

    return (
        <>
            <div
                className={classNames(
                    classes.tableContainer,
                    maxHeight && classes.maxHeight,
                    maxWidth && classes.maxWidth
                )}
            >
                {enableCheckboxes && (
                    <>
                        {tableToolbarActions && tableToolbarActions.length > 0 && (
                            <TableToolbar
                                selectedRows={selectedRows.length}
                                actions={tableToolbarActions(selectedRows)}
                            />
                        )}

                        {tableToolbarSplitActions && tableToolbarSplitActions.length > 0 && (
                            <TableToolbar
                                selectedRows={selectedRows.length}
                                splitActions={tableToolbarSplitActions(selectedRows)}
                            />
                        )}
                    </>
                )}

                <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
                    <Droppable droppableId="styled-table">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                <TableMui
                                    {...props}
                                    className={classNames(
                                        !!minWidth && classes.minWidth,
                                        props.className
                                    )}
                                    style={{ tableLayout: 'fixed' }}
                                >
                                    <>
                                        <TableHead>{columns && renderHeadRow(columns)}</TableHead>
                                        <TableMuiBody className={classes.tableBody}>
                                            {loading && <tr className={classes.loadingOverlay} />}
                                            {renderedRows}
                                            {provided.placeholder}
                                        </TableMuiBody>
                                    </>
                                </TableMui>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            <div className={classes.tableFooter}>
                <div />
                <div>
                    {pagination && pagination.totalPages > 0 && (
                        <TablePagination
                            className={classes.pagination}
                            disabled={loading}
                            currentPage={pagination.currentPage}
                            onPaginate={pagination.onPaginate}
                            totalPages={pagination.totalPages}
                        />
                    )}
                </div>
                <div />
            </div>
        </>
    );
}
