import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import ApiResponseBodyWithMeta from 'src/data/api/responses/ApiResponseBodyWithMeta';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    EVENTS_ACTIVATE_ENDPOINT,
    EVENTS_CLOSE_ENDPOINT,
    EVENTS_CREATE_ENDPOINT,
    EVENTS_DEACTIVATE_ENDPOINT,
    EVENTS_DELETE_ENDPOINT,
    EVENTS_GET_AVAILABLE_SEATS_ENDPOINT,
    EVENTS_GET_BY_ID_ENDPOINT,
    EVENTS_GET_ENDPOINT,
    EVENTS_GET_FILTER_OPTIONS_ENDPOINT,
    EVENTS_GET_ORDERS_ENDPOINT,
    EVENTS_GET_SEATING_PLAN_CATEGORIES_ENDPOINT,
    EVENTS_GET_SPLITS_ENDPOINT,
    EVENTS_GET_SUPPLIERS_ENDPOINT,
    EVENTS_LOCK_ENDPOINT,
    EVENTS_TICKET_SALES_CAP_ENDPOINT,
    EVENTS_UNLOCK_ENDPOINT,
    EVENTS_UPDATE_ENDPOINT,
    EVENTS_UPDATE_EVENT_CATEGORY_ENDPOINT,
    EVENTS_UPDATE_EVENT_INTERNAL_NOTES_ENDPOINT,
    EVENTS_UPDATE_NAME_ENDPOINT,
    EVENTS_UPDATE_ORGANIZER_ENDPOINT,
    EVENTS_UPDATE_SERIES_ENDPOINT,
} from 'src/data/constants/endpoints/events-endpoints';
import CreateEventDto from 'src/data/dtos/CreateEventDto';
import UpdateEventDto from 'src/data/dtos/UpdateEventDto';
import { SEATING_PLAN_CATEGORY_FILTER } from 'src/data/enums/seatingPlanCategoryFilter';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import { IdResponse } from 'src/data/models/common/idResponse';
import {
    Event,
    EventDetails,
    EventDetailsMeta,
    EventTicketsSalesCap,
} from 'src/data/models/events/event';
import EventFilters from 'src/data/models/events/eventFilters';
import { EventOverviewMeta } from 'src/data/models/events/eventOverviewMeta';
import EventOrder from 'src/data/models/order/EventOrder';
import Seat from 'src/data/models/seat/seat';
import { SeatingPlanCategoriesResponse } from 'src/data/models/seating-plan-category/SeatingPlanCategory';
import Split from 'src/data/models/split/split';

export interface EventOverviewFetchOptions extends BaseFetchOptions {
    seatingPlan?: string;
    purchaseId?: string;
    orderId?: string;
}

async function fetchEvents(
    options: EventOverviewFetchOptions = {
        includes: [],
    }
): Promise<ApiResponse<PaginatedApiResponseBody<Event, EventOverviewMeta>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.seatingPlan) {
        extraQueryParams.push({
            key: 'includeSeatingPlansFromSeatingPlanId',
            value: options.seatingPlan,
        });
    }

    if (options.purchaseId) {
        extraQueryParams.push({
            key: 'filter[purchaseId]',
            value: options.purchaseId,
        });
    }

    if (options.orderId) {
        extraQueryParams.push({
            key: 'filter[orderId]',
            value: options.orderId,
        });
    }

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Event, EventOverviewMeta>>(EVENTS_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchEventOrdersOverview(
    eventId: string,
    options: BaseFetchOptions = {
        includes: [],
    }
): Promise<ApiResponse<PaginatedApiResponseBody<EventOrder>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<EventOrder>>(EVENTS_GET_ORDERS_ENDPOINT(eventId), {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function fetchSeatingPlanCategories(
    eventId: string,
    filter?: SEATING_PLAN_CATEGORY_FILTER,
    options?: BaseFetchOptions,
    signal?: AbortSignal
) {
    if (filter) {
        options = {
            ...options,
            filter: [{ property: 'loadBy', value: filter }],
        };
    }

    options = {
        ...options,
        includes: ['AssignedCategories'],
    };

    const extraQueryParams: QueryParam[] = [];

    if (options?.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance().get<ApiResponseBody<SeatingPlanCategoriesResponse>>(
        EVENTS_GET_SEATING_PLAN_CATEGORIES_ENDPOINT(eventId),
        { params: options && getQueryParams(options, extraQueryParams), signal }
    );
}

async function fetchEventFilterOptions(
    options: BaseFetchOptions = {
        includes: [],
    }
): Promise<ApiResponse<ApiResponseBody<EventFilters>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<EventFilters>>(EVENTS_GET_FILTER_OPTIONS_ENDPOINT, {
            params: options && getQueryParams(options),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchSeatsByRowId(
    eventId: string,
    rowId: string
): Promise<ApiResponse<ApiResponseBody<Seat[]>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Seat[]>>(EVENTS_GET_AVAILABLE_SEATS_ENDPOINT(eventId, rowId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createEvent(dto: CreateEventDto): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(EVENTS_CREATE_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function fetchEventById(eventId: string, options?: BaseFetchOptions) {
    return await getInternalAxiosInstance().get<
        ApiResponseBodyWithMeta<EventDetails, EventDetailsMeta>
    >(EVENTS_GET_BY_ID_ENDPOINT(eventId), {
        params: options && getQueryParams(options),
    });
}

export async function fetchEventTicketSalesCaps(eventId: string, options?: BaseFetchOptions) {
    return await getInternalAxiosInstance().get<ApiResponseBody<EventTicketsSalesCap[]>>(
        EVENTS_TICKET_SALES_CAP_ENDPOINT(eventId),
        {
            params: options && getQueryParams(options),
        }
    );
}

export interface EventSupplier {
    canBeArchived: boolean;
    canBeDeleted: boolean;
    id: string;
    isArchived: boolean;
    name: string;
}

export async function fetchEventSuppliers(eventId: string, options?: BaseFetchOptions) {
    return await getInternalAxiosInstance().get<PaginatedApiResponseBody<EventSupplier>>(
        EVENTS_GET_SUPPLIERS_ENDPOINT(eventId),
        {
            params: options && getQueryParams(options),
        }
    );
}

async function updateEvent(
    eventId: string,
    dto: UpdateEventDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(EVENTS_UPDATE_ENDPOINT(eventId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface UpdateNameDto {
    name: string;
}

async function updateEventName(
    eventId: string,
    name: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    const dto: UpdateNameDto = { name };

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_UPDATE_NAME_ENDPOINT(eventId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateEventOrganizer(
    eventId: string,
    organizerId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_UPDATE_ORGANIZER_ENDPOINT(eventId), {
            organizerId,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateEventSeries(
    eventId: string,
    seriesId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_UPDATE_SERIES_ENDPOINT(eventId), {
            seriesId,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface UpdateEventCategoryDto {
    eventId: string;
    eventCategoryId: string;
}

export async function updateEventCategory({ eventId, eventCategoryId }: UpdateEventCategoryDto) {
    return await getInternalAxiosInstance().post<EmptyBody>(
        EVENTS_UPDATE_EVENT_CATEGORY_ENDPOINT(eventId),
        {
            eventCategoryId,
        }
    );
}

async function deleteEvent(eventId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_DELETE_ENDPOINT(eventId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deactivateEvent(eventId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_DEACTIVATE_ENDPOINT(eventId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function activateEvent(eventId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_ACTIVATE_ENDPOINT(eventId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchSplitsByEventId(
    eventId: string,
    options: BaseFetchOptions = {
        includes: [],
    }
): Promise<ApiResponse<PaginatedApiResponseBody<Split>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Split>>(EVENTS_GET_SPLITS_ENDPOINT(eventId), {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function lockEvent(eventId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_LOCK_ENDPOINT(eventId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function unlockEvent(eventId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_UNLOCK_ENDPOINT(eventId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function closeEvent(eventId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_CLOSE_ENDPOINT(eventId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function updateEventNotes(eventId: string, internalNotes: string) {
    return getInternalAxiosInstance().post<ApiResponseBody<EmptyBody>>(
        EVENTS_UPDATE_EVENT_INTERNAL_NOTES_ENDPOINT(eventId),
        {
            internalNotes,
        }
    );
}

export default {
    fetchEvents,
    fetchEventFilterOptions,
    fetchSeatsByRowId,
    fetchEventOrdersOverview,
    createEvent,
    fetchEventById,
    updateEvent,
    updateEventName,
    updateEventOrganizer,
    updateEventSeries,
    deleteEvent,
    fetchSplitsByEventId,
    deactivateEvent,
    activateEvent,
    lockEvent,
    unlockEvent,
    closeEvent,
};
