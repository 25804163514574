import Grid from '@material-ui/core/Grid';
import { Add } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useMemo, useState } from 'react';
import { type BookingOptionType } from 'src/data/services/booking-options-service';
import { BookingOption } from './booking-option';
import { BookingOptionModal } from './booking-option-modal';
import useFetchEventBookingOptions from './hooks/use-fetch-event-booking-options';

export const EventBookingOptions = ({
    eventId = '',
    canCreateBookingOptions = false,
}: {
    eventId: string | undefined;
    canCreateBookingOptions?: boolean;
}) => {
    const [showBookingOptionModal, setShowBookingOptionModal] = useState(false);

    const [bookingOptionToBeEdited, setBookingOptionToBeEdited] =
        useState<BookingOptionType | null>(null);

    const { data, isLoading, isError } = useFetchEventBookingOptions(eventId);

    const bookingOptions = data?.data?.data || [];

    const sortedOptions = useMemo(
        () =>
            [...bookingOptions].sort(
                (opt1, opt2) => (opt2.isDefault ? 1 : 0) - (opt1.isDefault ? 1 : 0)
            ),
        [bookingOptions]
    );

    if (!eventId)
        return (
            <Alert severity="info">
                Booking options can be created/modified after the event has been created. Default
                booking option will be the one that is used to create the event, and it can be
                changed later.
            </Alert>
        );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <h3
                            style={{
                                margin: '0',
                            }}
                        >
                            Booking options
                        </h3>
                        {canCreateBookingOptions && (
                            <Add
                                sx={{
                                    cursor: 'pointer',
                                    color: 'primary',
                                    background: (theme) => theme.palette.grey[100],
                                    borderRadius: 1,
                                    ':hover': {
                                        background: (theme) => theme.palette.grey[200],
                                    },
                                }}
                                onClick={() => setShowBookingOptionModal(true)}
                            />
                        )}
                    </Box>
                </Grid>

                {isLoading && <p>Loading booking options...</p>}
                {isError ? <Alert severity="error">Error loading booking options</Alert> : null}

                {!isLoading &&
                    sortedOptions.map((option) => {
                        return (
                            <BookingOption
                                key={option.id}
                                option={option}
                                onClickEdit={() => {
                                    setBookingOptionToBeEdited(option);
                                    setShowBookingOptionModal(true);
                                }}
                            />
                        );
                    })}
                {showBookingOptionModal && (
                    <BookingOptionModal
                        eventId={eventId}
                        isOpen={showBookingOptionModal}
                        onClose={() => {
                            setShowBookingOptionModal(false);
                            setBookingOptionToBeEdited(null);
                        }}
                        bookingOption={bookingOptionToBeEdited || undefined}
                    />
                )}
            </Grid>
        </>
    );
};
