import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';
import {
    PURCHASES_CANCEL_ENDPOINT,
    PURCHASES_COMPLETE_ENDPOINT,
    PURCHASES_CREATE_ENDPOINT,
    PURCHASES_DELETE_SPLIT_ENDPOINT,
    PURCHASES_GET_BY_ID_ENDPOINT,
    PURCHASES_GET_ENDPOINT,
    PURCHASES_GET_EVENTS,
    PURCHASES_GET_STATISTICS_ENDPOINT,
    PURCHASES_MULTIPLE_COMPLETE_ENDPOINT,
    PURCHASES_UPDATE_ENDPOINT,
    PURCHASE_ADD_TICKETS_ENDPOINT,
    PURCHASE_DELETE_TICKETS_ENDPOINT,
    PURCHASE_GET_PRICES_ENDPOINT,
    PURCHASE_GET_TICKETS_ENDPOINT,
    PURCHASE_UPDATE_PRICES_ENDPOINT,
} from 'src/data/constants/endpoints/purchases-endpoints';
import CreatePurchaseDto from 'src/data/dtos/CreatePurchaseDto';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import { IdResponse } from 'src/data/models/common/idResponse';
import { PaymentMethod } from 'src/data/models/payment-method/paymentMethod';
import Purchase from 'src/data/models/purchase/Purchase';
import PurchaseStatistic from 'src/data/models/purchase/PurchaseStatistic';
import Price from '../models/common/price';
import Ticket from '../models/tickets/ticket';

export async function fetchPurchases(
    options: BaseFetchOptions,
    eventId?: string
): Promise<ApiResponse<PaginatedApiResponseBody<Purchase, PaginationMeta>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    if (eventId) {
        extraQueryParams.push({
            key: 'filter[eventIds]',
            value: eventId,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Purchase>>(PURCHASES_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchPurchaseById(
    purchaseId: string
): Promise<ApiResponse<ApiResponseBody<Purchase>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Purchase>>(PURCHASES_GET_BY_ID_ENDPOINT(purchaseId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteSplit(
    purchaseId: string,
    splitId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(PURCHASES_DELETE_SPLIT_ENDPOINT(purchaseId, splitId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface UpdatePurchaseDto {
    paymentMethod?: PaymentMethod;
    externalReferenceId?: string;
    notes?: string;
    finalized: boolean;
    purchaseType?: string;
}

async function updatePurchase(
    purchaseId: string,
    dto: UpdatePurchaseDto
): Promise<ApiResponse<ApiResponseBody<Purchase>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<Purchase>>(PURCHASES_UPDATE_ENDPOINT(purchaseId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createPurchase(
    dto: CreatePurchaseDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(PURCHASES_CREATE_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function cancelPurchase(
    purchaseId: string
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(PURCHASES_CANCEL_ENDPOINT(purchaseId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function completePurchase(
    purchaseId: string
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(PURCHASES_COMPLETE_ENDPOINT(purchaseId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function completePurchases(
    purchaseIds: string[]
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(PURCHASES_MULTIPLE_COMPLETE_ENDPOINT(), {
            ids: purchaseIds,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchPurchaseStatistics(
    purchaseId: string,
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<PurchaseStatistic>>> {
    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<PurchaseStatistic>>(
            PURCHASES_GET_STATISTICS_ENDPOINT(purchaseId),
            {
                params: options && getQueryParams(options),
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface PurchasePrice {
    eventId: string;
    eventName: string;
    originalPrice: Price;
    quantity: number;
    seatingPlanCategoryId: string;
    seatingPlanCategoryName: string;
    bookingOptionName: string;
    bookingOptionId: string;
    // ticketsPerSplit?: number;
}

async function fetchPurchasePrices(
    purchaseId: string
): Promise<ApiResponse<PaginatedApiResponseBody<PurchasePrice>>> {
    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<PurchasePrice>>(PURCHASE_GET_PRICES_ENDPOINT(purchaseId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchPurchaseTickets(
    purchaseId: string,
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Ticket>>> {
    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Ticket>>(PURCHASE_GET_TICKETS_ENDPOINT(purchaseId), {
            params: options && getQueryParams(options),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

interface UpdateCategoryPriceDto {
    eventId: string;
    seatingPlanCategoryId: string;
    pricePerTicket: number;
}
export interface UpdatePurchasePriceDto {
    purchaseOriginalPrice: number;
    seatingPlanCategoryBookingOptionPrices: UpdateCategoryPriceDto[];
}

async function updatePurchasePrice(
    purchaseId: string,
    dto: UpdatePurchasePriceDto
): Promise<ApiResponse<PaginatedApiResponseBody<Ticket>>> {
    return await getInternalAxiosInstance()
        .post<PaginatedApiResponseBody<Ticket>>(PURCHASE_UPDATE_PRICES_ENDPOINT(purchaseId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export type DeleteTicketsFromDTO = {
    purchaseOriginalPrice: number;
    SeatingPlanCategoryBookingOptionPrices: {
        eventId: string;
        seatingPlanCategoryId: string;
        pricePerTicket: number;
    }[];
    ticketIds: string[];
};

async function deleteTicketsFromPurchase(
    purchaseId: string,
    dto: DeleteTicketsFromDTO
): Promise<ApiResponse<PaginatedApiResponseBody<Ticket>>> {
    return await getInternalAxiosInstance()
        .post<PaginatedApiResponseBody<Ticket>>(PURCHASE_DELETE_TICKETS_ENDPOINT(purchaseId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export type AddTicketsToPurchaseDTO = {
    purchaseOriginalPrice: number;
    seatingPlanCategoryBookingOptionPrices: {
        eventId: string;
        seatingPlanCategoryId: string;
        pricePerTicket: number;
    }[];
    tickets: {
        eventId: string;
        quantity: number;
        ticketsPerSplit?: number;
        seatingPlanCategoryId: string;
    }[];
};

async function addTicketsToPurchase(
    purchaseId: string,
    dto: AddTicketsToPurchaseDTO
): Promise<ApiResponse<PaginatedApiResponseBody<Ticket>>> {
    return await getInternalAxiosInstance()
        .post<PaginatedApiResponseBody<Ticket>>(PURCHASE_ADD_TICKETS_ENDPOINT(purchaseId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

type PurchaseEvent = {
    eventId: string;
    eventName: string;
};

export async function fetchPurchasesEvents(
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<PurchaseEvent>>> {
    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<PurchaseEvent>>(PURCHASES_GET_EVENTS, {
            params: options && getQueryParams(options),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    fetchPurchases,
    deleteSplit,
    fetchPurchaseById,
    updatePurchase,
    createPurchase,
    cancelPurchase,
    completePurchase,
    completePurchases,
    fetchPurchaseStatistics,
    fetchPurchasePrices,
    fetchPurchaseTickets,
    updatePurchasePrice,
    deleteTicketsFromPurchase,
    addTicketsToPurchase,
};
