import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import { Grid, Paper, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import CreateTicketSalesCapFormDataWrapper from 'src/app/components/data-wrappers/tickets-sales-cap/CreateTicketsSalesCapFormDataWrapper';
import DeleteTicketsSalesCapDataWrapper from 'src/app/components/data-wrappers/tickets-sales-cap/DeleteTicketsSalesCapDataWrapper';
import EventDetailsDictionary from 'src/app/components/events/event-details-page/event-details-dictionary';
import { EventInternalNotes } from 'src/app/components/events/event-details-page/event-internal-notes';
import AuditLogFeature from 'src/app/components/features/audit-log/AuditLogFeature';
import { TableHeaderTotalFeature } from 'src/app/components/features/tables/TableHeaderTotalFeature';
import DetailsSalesTable from 'src/app/components/tables/DetailsSalesTable';
import TicketSalesCapsTable from 'src/app/components/tables/TicketSalesCapsTable';
import {
    FETCH_EVENT_BY_ID_QUERY_KEY,
    useFetchEventById,
} from 'src/app/hooks/events/useFetchEventById';
import { FETCH_EVENT_TICKET_SALES_CAPS_QUERY_KEY } from 'src/app/hooks/sales-caps/useFetchTicketSalesCaps';
import useQueryParams from 'src/app/hooks/useQueryParams';
import travelwareUrlService from 'src/app/pages/events/travelwareUrlService';
import FilterOption from 'src/data/api/common/FilterOption';
import { EventSeatingPlanCategory, EventTicketsSalesCap } from 'src/data/models/events/event';
import { dateFormat } from 'src/shared/date';
import Button from 'src/view/components/button/Button';
import Chip from 'src/view/components/chip/Chip';
import { ETBContainer } from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import StatusChip from 'src/view/components/status-chip/StatusChip';
import { ActivityLogFeature } from './activity-log/ActivityLogFeature';
import EventDetailsFilterFeature, {
    useEventDetailsFilterOptions,
} from './event/EventDetailsFilterFeature';

interface Props {
    eventId: string;
    invalidatedAt?: number;
    onDetailsClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    spacingRight: {
        marginRight: theme.spacing(1),
    },
    spacingLeft: {
        marginLeft: theme.spacing(1),
    },
    minHeight: {
        height: 'fit-content',
    },
    fullWidth: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    dateSeparation: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    tscTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    categoriesSwitchLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: '.675rem',
            color: theme.palette.tertiary.main,
        },
    },
    showMoreCategoriesWrapper: {
        width: '100%',
        textAlign: 'center',
        color: theme.colors.linkBlue,
        textDecoration: 'underline',
    },
    showMoreCategoriesText: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    detailsTscGrid: {
        marginTop: theme.spacing(0),
    },
}));

export default function EventDetailsFeature({ eventId, onDetailsClick }: Props): JSX.Element {
    const classes = useStyles();
    const queryClient = useQueryClient();
    const [isTscHistoryModalOpen, setIsTscHistoryModalOpen] = useState(false);
    const [ticketsSalesCapId, setTicketsSalesCapId] = useState<string | undefined>();
    const [ticketsSalesCapsModalOpen, setTicketsSalesCapsModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [showAuditLogModal, setShowAuditLogModal] = useState(false);
    const [showHiddenCategories, setShowHiddenCategories] = useState(false);
    const mapEventTicketSalesCaps = (seatingPlanCategories?: EventSeatingPlanCategory[]) => {
        if (!seatingPlanCategories) return [];

        const ticketSalesCaps: EventTicketsSalesCap[] = [];

        seatingPlanCategories.forEach((s) => {
            s.eventTicketSalesCaps.forEach((tsc) =>
                ticketSalesCaps.push({
                    ...tsc,
                    seatingPlanCategoryName: tsc.seatingPlanCategoryName || s.name,
                })
            );
        });

        return ticketSalesCaps;
    };

    const { data, isLoading: loading } = useFetchEventById(
        eventId,
        {
            includes: ['statistics', 'seatingPlanCategories'],
        },
        { enabled: !!eventId }
    );

    const {
        values: { filterOptions },
        setFilterOptions,
    } = useQueryParams('event_details');

    const {
        defaultValues,
        hasFinishedFormingDefaultValues,
        seatingPlanCategoriesDropdownOptions,
        bookingOptionsDropdownOptions,
    } = useEventDetailsFilterOptions({
        eventId,
        filterOptions,
    });

    if (loading) return <LoadingOverlay />;
    if (!data) return <></>;

    const { name, isActive, dateTimeStart, dateTimeEnd, dateConfirmed } = data.data.data;
    const { seatingPlanCategories } = data.data.meta;

    const filledCategories =
        seatingPlanCategories?.filter((spc) => spc.ticketsTotal !== 0 || spc.ticketsSold !== 0) ||
        [];
    const categoriesToShow =
        (showHiddenCategories ? seatingPlanCategories : filledCategories) || [];

    const emptyCategories =
        seatingPlanCategories
            ?.filter((spc) => spc.ticketsTotal === 0 && spc.ticketsSold === 0)
            .filter((spc) => !spc.isArchived) || [];

    const filteredCategories = getFilteredCategories({ categoriesToShow, filterOptions });

    return (
        <>
            <Header>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <Grid container spacing={0.5}>
                            <Grid item flexShrink={0} alignItems="center">
                                <h1 className={classes.spacingRight} style={{ flexShrink: 0 }}>
                                    {name}
                                </h1>
                            </Grid>
                            <Grid item display="flex" alignItems="center">
                                <Chip
                                    rounded
                                    label={moment.utc(dateTimeStart).format(dateFormat)}
                                />
                                {dateTimeEnd ? (
                                    <>
                                        <span className={classes.dateSeparation}>-</span>
                                        <Chip
                                            rounded
                                            label={
                                                dateTimeEnd
                                                    ? moment.utc(dateTimeEnd).format(dateFormat)
                                                    : 'Unknown'
                                            }
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid item display="flex" alignItems="center">
                                <StatusChip
                                    label={dateConfirmed ? 'Date confirmed' : 'Date not confirmed'}
                                    variant={dateConfirmed ? 'success' : 'warning'}
                                    Icon={dateConfirmed ? <CheckIcon /> : <></>}
                                />
                            </Grid>

                            <Grid item display="flex" alignItems="center">
                                <StatusChip
                                    label={isActive ? 'Active' : 'Not Active'}
                                    variant={isActive ? 'success' : 'warning'}
                                    Icon={isActive ? <CheckIcon /> : <></>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    className={classes.fullWidth}
                                    onClick={() => setShowAuditLogModal(true)}
                                    color="primary"
                                    variant="text"
                                    startIcon={<AccessTimeIcon />}
                                >
                                    Activity Log
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.fullWidth}
                                    href={travelwareUrlService.events(eventId)}
                                    color="primary"
                                    target="_blank"
                                >
                                    Open in Travelware
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.fullWidth}
                                    href={travelwareUrlService.orders(eventId)}
                                    color="primary"
                                    target="_blank"
                                >
                                    Travelware Orders
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={classes.fullWidth}
                                    color="primary"
                                    variant="text"
                                    white
                                    onClick={onDetailsClick}
                                >
                                    Edit Event
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Header>
            <ETBContainer sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Paper>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TableHeaderTotalFeature
                                title="Details"
                                totalResults={filteredCategories?.length || 0}
                            />

                            {hasFinishedFormingDefaultValues && (
                                <EventDetailsFilterFeature
                                    defaultValues={defaultValues}
                                    onChangeFilterOptions={setFilterOptions}
                                    seatingPlanCategoriesDropdownOptions={
                                        seatingPlanCategoriesDropdownOptions
                                    }
                                    bookingOptionsDropdownOptions={bookingOptionsDropdownOptions}
                                />
                            )}

                            <DetailsSalesTable
                                eventSeatingplanCategories={filteredCategories.sort((a, b) =>
                                    a.bookingOptionName.localeCompare(b.bookingOptionName)
                                )}
                                loading={loading}
                            />
                        </Grid>
                        {emptyCategories.length > 0 && (
                            <div className={classes.showMoreCategoriesWrapper}>
                                <span
                                    className={classes.showMoreCategoriesText}
                                    onClick={() => setShowHiddenCategories(!showHiddenCategories)}
                                >
                                    {`${
                                        showHiddenCategories ? 'Hide empty' : 'Show hidden'
                                    } categories (${emptyCategories.length})`}
                                </span>
                            </div>
                        )}

                        <Grid container item xs={12}>
                            <Grid item xs={8} md={6} lg={4} marginLeft="auto">
                                <EventInternalNotes
                                    eventId={eventId}
                                    internalNotes={data.data.data.internalNotes}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={5} className={classes.detailsTscGrid}>
                            <Grid item xs={6}>
                                <div className={classes.tscTitle}>
                                    <Button
                                        variant="text"
                                        startIcon={<AccessTimeIcon />}
                                        className={classNames(
                                            classes.minHeight,
                                            classes.spacingLeft,
                                            classes.fullWidth
                                        )}
                                        onClick={() => {
                                            setIsTscHistoryModalOpen(true);
                                        }}
                                    >
                                        History
                                    </Button>
                                    <Button
                                        variant="text"
                                        dataCy="add-tickets-sales-caps-button"
                                        startIcon={<AddCircleOutlineIcon />}
                                        className={classNames(
                                            classes.minHeight,
                                            classes.spacingLeft,
                                            classes.fullWidth
                                        )}
                                        onClick={() => {
                                            setTicketsSalesCapsModalOpen(true);
                                            setTicketsSalesCapId(undefined);
                                        }}
                                    >
                                        Add tickets sales caps
                                    </Button>
                                </div>
                                <TableHeaderTotalFeature
                                    title="Ticket sales caps"
                                    totalResults={
                                        mapEventTicketSalesCaps(seatingPlanCategories).length
                                    }
                                />

                                <TicketSalesCapsTable
                                    eventId={eventId}
                                    onEditClick={(id: string) => {
                                        setTicketsSalesCapId(id);
                                        setTicketsSalesCapsModalOpen(true);
                                    }}
                                    onDeleteClick={(id: string) => {
                                        setTicketsSalesCapId(id);
                                        setConfirmationModalOpen(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={5}>
                                <EventDetailsDictionary eventDetails={data.data.data} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </ETBContainer>
            <Modal
                title={ticketsSalesCapId ? 'Edit tickets sales caps' : 'Add tickets sales caps'}
                open={ticketsSalesCapsModalOpen}
                onClose={() => setTicketsSalesCapsModalOpen(false)}
            >
                <CreateTicketSalesCapFormDataWrapper
                    eventId={eventId}
                    id={ticketsSalesCapId}
                    onSucceed={() => {
                        setTicketsSalesCapsModalOpen(false);

                        // TODO: Move inside self-contained hooks where they must invalidate relevant data
                        // Currently CreateTicketSalesCap is using old data wrappers.
                        // Will be done once we use React-Query for every data fetch
                        queryClient.invalidateQueries({
                            queryKey: [FETCH_EVENT_BY_ID_QUERY_KEY],
                            exact: false,
                        });

                        queryClient.invalidateQueries({
                            queryKey: [FETCH_EVENT_TICKET_SALES_CAPS_QUERY_KEY],
                            exact: false,
                        });
                    }}
                />
            </Modal>
            ;
            {ticketsSalesCapId && (
                <Modal
                    title="Confirm your action"
                    open={confirmationModalOpen}
                    onClose={() => setConfirmationModalOpen(false)}
                >
                    <DeleteTicketsSalesCapDataWrapper
                        eventId={eventId}
                        id={ticketsSalesCapId}
                        onSucceed={() => {
                            setConfirmationModalOpen(false);
                        }}
                    />
                </Modal>
            )}
            <Modal open={showAuditLogModal} onClose={() => setShowAuditLogModal(false)}>
                <AuditLogFeature groupEntityId={eventId} />
            </Modal>
            {isTscHistoryModalOpen && (
                <Modal open={true} onClose={() => setIsTscHistoryModalOpen(false)} width="x-large">
                    <ActivityLogFeature groupingEntityId={eventId || ''} />
                </Modal>
            )}
        </>
    );
}

/** Filters seating plan categories based on filter options
 * @returns Seating plan categories matching either `seatingPlanCategoryId` or `bookingOptionId`. If both filters are applied, both must match.
 * */
const getFilteredCategories = ({
    categoriesToShow,
    filterOptions,
}: {
    categoriesToShow: EventSeatingPlanCategory[];
    filterOptions: FilterOption[];
}) => {
    const selectedSeatingPlanCategoryIds = filterOptions
        .find((f) => f.property === 'seatingPlanCategoryId')
        ?.value.split(',');
    const selectedBookingOptionIds = filterOptions
        .find((f) => f.property === 'bookingOptionIds')
        ?.value.split(',');

    const hasAnyFilters =
        selectedSeatingPlanCategoryIds?.length || selectedBookingOptionIds?.length;

    // If no filters applied, return all categories, and skip filtering
    if (!hasAnyFilters) return categoriesToShow;

    return categoriesToShow?.filter((category) => {
        const matchesSeatingPlan =
            selectedSeatingPlanCategoryIds?.length === 0 ||
            selectedSeatingPlanCategoryIds?.includes(category.seatingPlanCategoryId);

        const matchesBookingOption =
            selectedBookingOptionIds?.length === 0 ||
            selectedBookingOptionIds?.includes(category.bookingOptionId);

        // Logic: Both filters applied -> both must match; Otherwise, at least one must match
        if (selectedSeatingPlanCategoryIds?.length && selectedBookingOptionIds?.length) {
            return matchesSeatingPlan && matchesBookingOption;
        }

        return matchesSeatingPlan || matchesBookingOption;
    });
};
